<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="旧密码" prop="opassword">
        <el-input v-model="info.opassword" show-password placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input v-model="info.password" show-password placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="重复密码" prop="password2">
        <el-input v-model="info.password2" show-password placeholder="请输入重复密码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      disable: false,
      info: {
        opassword: '',
        password: '',
        password2: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        opassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        password2: [
          { required: true, message: '请输入重复密码', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    submitForm(formName) {
      var that = this
      if(that.disable) {
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(that.info.password != that.info.password2) {
            that.fail('两次输入密码不一致！')
            return false
          }
          that.disable = true
          var param = {
            opassword: that.info.opassword,
            password: that.info.password
          }
          this.$api.merchant.myUpdatePwd(param,function (res) {
            if(res.errcode == 0) {
              that.$router.push({name: 'login'})
              that.success(res.errmsg)
            } else {
              that.disable = false
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
